import React, { useMemo } from 'react';
import { Button, Fieldset, Panel, TextField } from 'react95';
import './play.scss';

import All from '../../Assets/All_Functional.gif';
import NetworkMotherboardPower from '../../Assets/network_motherboard_power.gif';
import MotherboardPower from '../../Assets/motherboard_power.gif';
import Power from '../../Assets/power.gif';
import AllDisabled from '../../Assets/all_disabled.gif';
import Defused from '../../Assets/defused.gif';

export default function Play ({ setError, initialState, ...props })
{
    const [messages, setMessages] = React.useState([]);
    const [inputText, setInputText] = React.useState("");
    const [step, setStep] = React.useState(1);
    const [inputEnabled, setInputEnabled] = React.useState(true);
    const [systemInformation, setSystemInformation] = React.useState("");
    const [rows] = React.useState(1);
    const [timerInterval, setTimerInterval] = React.useState(1);
    const [stopTimer, setStopTimer] = React.useState(false);
    const [timerFrozen, setTimerFrozen] = React.useState(false);
    const [image, setImage] = React.useState(All);
    const [components, setComponents] = React.useState(["Network", "Memory", "Motherboard", "Power"])

    const timerDecrement = (state, action) => {
        if (action && (action.type === "setInitial")) {
            return action.payload;
        } else {
            return state - (1 + timePenalty);
        }
    }
    const [timePenalty, setTimePenalty] = React.useState(0);
    const [timer, timerDispatch] = React.useReducer(timerDecrement, 60 * 60)

    const userInput = React.createRef();

    // const setMessages = useCallback(obj => {
    //   setRealMessages(obj)
    //   // eslint-disable-next-line
    // }, [messages])

    React.useEffect(() => {
        localStorage.setItem('timer', JSON.stringify(timer));
        localStorage.setItem('messages', JSON.stringify(messages));
        localStorage.setItem('inputText', JSON.stringify(inputText));
        localStorage.setItem('step', JSON.stringify(step));
        localStorage.setItem('inputEnabled', JSON.stringify(inputEnabled));
        localStorage.setItem('systemInformation', JSON.stringify(systemInformation));
        localStorage.setItem('timerInterval', JSON.stringify(timerInterval));
        localStorage.setItem('stopTimer', JSON.stringify(stopTimer));
        localStorage.setItem('timerFrozen', JSON.stringify(timerFrozen));
        localStorage.setItem('image', JSON.stringify(image));
    }, [timer, messages, inputText, step, inputEnabled, systemInformation, timerInterval, stopTimer, timerFrozen, image]);

    React.useEffect(() => {
        console.log(initialState);
        if (Object.keys(initialState).length !== 0 && initialState.shouldLoad) {
            timerDispatch({ type: 'setInitial', payload: initialState.timer});
            setMessages(initialState.messages);
            setInputText(initialState.inputText);
            setStep(initialState.step);
            setSystemInformation(initialState.systemInformation);
            setTimerInterval(initialState.timerInterval);
            setStopTimer(initialState.stopTimer);
            setTimerFrozen(initialState.timerFrozen);
            setImage(initialState.image);
        } else {
            setMessages([
                {
                    you: false,
                    message: "There seem to be four components on the dissemination device, which all feed into the main pump. If we take all four of them offline that should disable the device."
                }
            ])
            setTimeout(() => {
                setTimeout(() => {
                    setMessages([
                        {
                            you: false,
                            message: "There seem to be four components on the dissemination device, which all feed into the main pump. If we take all four of them offline that should disable the device."
                        },
                        {
                            you: false,
                            message: `We need to work out which component to work on first. This machine seems very delicate so we need to make sure we disable the components in the correct order otherwise there could be catastrophic consequences. <br/><br/> So what should we start on? <br/> ${remainingComponentString}`
                        }
                    ])
                }, 1500);
            }, 1500);
        }
    // eslint-disable-next-line
    }, [initialState]);

    const handleKeyDown = (ev) => {
        if (ev.keyCode === 13 && inputEnabled) {
            sendMessage();
        }
    }

    const wireCutFailMessage = useMemo(() => {
        if (!timerFrozen) {
            return 'Oh no, it seems like that was the wrong wire... and the timer has sped up. Try not to get it wrong again!<br/><br/>Which wire would you like me to cut?'
        } else {
            return 'Oh no, it seems like that was the wrong wire... Try not to get it wrong again!<br/><br/>Which wire would you like me to cut?'
        }
    }, [timerFrozen])

    const ChatLog = React.useMemo(() => {
        return (
            <div style={{ position: 'absolute', left: 0, top: 0, paddingLeft: '1rem', paddingRight: '1rem' }}>
                { messages.map((m, i) => {
                    return (
                        <div key={`msg-${i}`} className={`message ${ m.you ? 'you' : 'them' }`}>
                            <div className="sender">{ m.you ? 'User:' : 'Claire:'}</div>
                            <div className="content" dangerouslySetInnerHTML={{ __html: m.message }}/>
                        </div>
                    );
                })}
            </div>
        );
    }, [messages])

    const sendMessage = () => {
        handleInput(userInput.current.value);
        userInput.current.value = '';
    }

    const checkIfModule = (input) => {
        return ['network', 'power', 'motherboard', 'memory'].includes(input)
    }

    const calculateTimerString = (currentTime, offset) => {
      if (offset) currentTime = currentTime - offset
      return `${Math.floor(currentTime/60) }:${ (currentTime % 60 < 10 ? `0${currentTime % 60}` : currentTime % 60)}`
    }

    const timerValue = useMemo(() => {
      return calculateTimerString(timer)
    }, [timer])

    const elapsedTime = (t, offset) => {
        return calculateTimerString((60 * 60) - t, offset)
    }

    const remainingComponentString = useMemo(() => {
        return components.map(a => `<br/>&bull; ${a}`).join('')
    }, [components])

    const handleInput = input => {
        let lowerInput = input.toLowerCase();
        let delay = 2000;

        setMessages([ ...messages, {
            you: true,
            message: input
        }]);

        // Computer Modules
        if (step === 1 || step === 5 || step === 7) {
            setTimeout(() => {
                setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: "Ok, I'll try that component..."
                    }
                ]);
            }, delay / 2)
        }

        // Memory hints
        if (step === 4 || step === 3 || step === 2) {
            setTimeout(() => {
                setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: "Ok, I'll type that in and see what it says..."
                    }
                ]);
            }, delay / 2)
        }

        setTimeout(() => {
            if (step === 1) {
                //if (['network', 'motherboard', 'power', 'memory'].includes(lowerInput)) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll try that component..."
                        },
                        {
                            you: false,
                            message: `That wasn't one of the options I'm afraid. Let's try again.<br/><br/> What will it be? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                    if (['network', 'motherboard', 'power'].includes(lowerInput)) {
                        setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll try that component..."
                        },
                        {
                            you: false,
                            message: `Oh no, it seems like that was the wrong component, and worse still, it seems like the timers jumped down by another five minutes! Don't make another mistake, we can't afford to lose any more time! <br/><br/> What will it be? <br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                    }

                    if (lowerInput === 'memory') {
                        setInputEnabled(false);
                        setComponents(components.filter(a => a !== 'Memory'))
                        setSystemInformation("Hello, I am the device's memory system. It seems that some of my memory has been 𝓬σяя𝕌ｐtＥ𝓭. It is lovely to 爪ＥＥt you, would you like to be my ⓕя𝐢ＥŇ𝓭?");

                        setTimeout(() => {
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: "Ok, I'll try that component..."
                                },
                                {
                                    you: false,
                                    message: "Right, that seems to be the right choice, something seems to have popped up in the system information panel."
                                }
                            ]);

                            setTimeout(() => {
                                setMessages([ ...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: "Ok, I'll try that component..."
                                    },
                                    {
                                        you: false,
                                        message: "Right, that seems to be the right choice, something seems to have popped up in the system information panel."
                                    },
                                    {
                                        you: false,
                                        message: "It seems like they've deleted a lot of the Memory System's history, but irritatingly they've left on some kind of sentient AI programme..."
                                    }
                                ]);

                                setTimeout(() => {
                                    setSystemInformation("I rarely get to speak to people. Let me tell you a story.");
                                    setTimeout(() => {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: "Ok, I'll try that component..."
                                            },
                                            {
                                                you: false,
                                                message: "Right, that seems to be the right choice, something seems to have popped up in the system information panel."
                                            },
                                            {
                                                you: false,
                                                message: "It seems like they've deleted a lot of the Memory System's history, but irritatingly they've left on some kind of sentient AI programme..."
                                            },
                                            {
                                                you: false,
                                                message: "I suspect there's something in its history that can help us shut it down. See if you can restore some of its deleted files..."
                                            }
                                        ]);

                                        setTimeout(() => {
                                            setSystemInformation("Oh it appears all my stories have been wiped from my memory.");
                                            setTimeout(() => {
                                                setMessages([ ...messages,
                                                {
                                                    you: true,
                                                    message: input
                                                },
                                                {
                                                    you: false,
                                                    message: "Ok, I'll try that component..."
                                                },
                                                {
                                                    you: false,
                                                    message: "Right, that seems to be the right choice, something seems to have popped up in the system information panel."
                                                },
                                                {
                                                    you: false,
                                                    message: "It seems like they've deleted a lot of the Memory System's history, but irritatingly they've left on some kind of sentient AI programme..."
                                                },
                                                {
                                                    you: false,
                                                    message: "I suspect there's something in its history that can help us shut it down. See if you can restore some of its deleted files..."
                                                },
                                                {
                                                    you: false,
                                                    message: "Let's try to find a keyword to restore one of the system's memories - I’m sure there’s something still buried within its code. Maybe once we know more about it we can find a way to shut it down for good. We should start with recent memories and work our way back. <br/><br/> - Send me the word you think will help."
                                                }
                                                ]);
                                                setInputEnabled(true);
                                                setStep(2);
                                            }, delay);
                                        }, 4000);
                                    }, delay);
                                }, delay);
                            }, delay)
                        }, delay);
                    }
                // }
                //else
                //{
                    //setMessages([ ...messages,
                        //{
                            //you: true,
                            //message: input
                       // },
                        //{
                           // you: false,
                           // message: "That wasn't one of the options I'm afraid. Let's try again.<br/><br/> What will it be? <br/><br/> &bull; Network <br/> &bull; Memory <br/> &bull; Motherboard <br/> &bull; Power"
                       // }
               // }
            }

            if (step === 2) {
                if (lowerInput === 'sausages') {
                    setSystemInformation('Oh gosh, it\'s all coming back to me. The sausage poisoning at the peace talks. Three people dead... and I... made it happen... oh gosh. What other atrocities have I committed?');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll type that in and see what it says..."
                        },
                        {
                            you: false,
                            message: 'Great work! It seems like it wants to learn more about itself. Let\'s try and find another keyword from this machine\'s past... <br/><br/> - Send me the word you think will help.'
                        }
                    ]);
                    setStep(3);
                } else {
                    setSystemInformation('What are you saying? You humans are so strange...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll type that in and see what it says..."
                        },
                        {
                            you: false,
                            message: "That doesn't seem to be the word it responds to, let's try something different... <br/><br/> - Send me the word you think will help."
                        }
                    ]);
                }
            }

            if (step === 3) {
                if (lowerInput === 'yemen') {
                    setSystemInformation('All those people… covered in crude oil… and on fire… What am I... have I always been involved in awful incidents? It\'s almost too much for me to bear, but I need to remember...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll type that in and see what it says..."
                        },
                        {
                            you: false,
                            message: 'It wants to know more. Pandering to an AI seems strange, but we\'ve come this far. See if you can find another. <br/><br/> - Send me the word you think will help.'
                        }
                    ]);
                    setStep(4);
                } else {
                    setSystemInformation('What are you saying? You humans are so strange...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll type that in and see what it says..."
                        },
                        {
                            you: false,
                            message: "That doesn't seem to be the word it responds to, let's try something different... <br/><br/> - Send me the word you think will help."
                        }
                    ]);
                }
            }

            if (step === 4) {
                if (lowerInput === 'platypus') {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                    ]);
                    setInputEnabled(false);
                    setSystemInformation('Platypus. Platy... pus... I thought no memory could be worse than killing all those people in Yemen. But the senseless destruction of those cuckoo clocks is too much. I can’t bear it. I must be stopped... I must stop myself...');
                    setTimeout(() => {
                        setSystemInformation('--- MEMORY WILL SELF-DESTRUCT NOW ---');
                        setTimeout(() => {
                            setSystemInformation('--- Memory system offline ---');
                            setImage(NetworkMotherboardPower);
                            setTimeout(() => {
                                setSystemInformation("");
                                setMessages([ ...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: "Ok, I'll type that in and see what it says..."
                                    },
                                    {
                                        you: false,
                                        message: `Well that was unusual. But it seems we have taken one of the components offline. Only three to go. <br/><br/> Which of the components is next in the order? <br/> ${remainingComponentString}`
                                    },
                                ]);
                                setInputEnabled(true);
                                setStep(5);
                            }, 4000);
                        }, 4000)
                    }, 8000)
                } else {
                    setSystemInformation('What are you saying? You humans are so strange...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll type that in and see what it says..."
                        },
                        {
                            you: false,
                            message: "That doesn't seem to be the word it responds to, let's try something different... <br/><br/> - Send me the word you think will help."
                        }
                    ]);
                }
            }

            if (step === 5) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll try that component..."
                        },
                        {
                            you: false,
                            message: `That wasn't one of the options I'm afraid. Let's try again.<br/><br/> What will it be? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                if (['motherboard', 'power'].includes(lowerInput)) {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll try that component..."
                        },
                        {
                            you: false,
                            message: `Oh no! That's the wrong component and the timer has gone down by five minutes. <br/><br/> Which of the components is next in the order? <br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                }

                if (lowerInput === 'network') {
                    setInputEnabled(false);

                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setSystemInformation("The Z8 Water Treatment Plant Network.\n\n Every node in this network is connected to two other nodes, creating a loop which contains all network nodes. Each node can only connect to another node it shares at least one part of its name with (in any position), but can’t connect to two nodes that share the same part of its name - e.g. node A1B could connect to either A2C or D3A, but not both.");

                    setTimeout(() => {
                        setMessages([...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: "Ok, I'll try that component..."
                            },
                            {
                                you: false,
                                message: 'Right, that seems to be the right choice, it does seem sensible to take the device off the wifi system so that Yakov can\'t communicate with it remotely.<br/><br/>Something seems to have popped up on the System Information screen again'
                            }
                        ]);

                        setTimeout(() => {
                            setMessages([...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: "Ok, I'll try that component..."
                                },
                                {
                                    you: false,
                                    message: 'Right, that seems to be the right choice, it does seem sensible to take the device off the wifi system so that Yakov can\'t communicate with it remotely.<br/><br/>Something seems to have popped up on the System Information screen again'
                                },
                                {
                                    you: false,
                                    message: 'We need to find the two nodes connected to the network node on the device. Once I know what the two nodes are, I can deactivate them.<br/><br/>When you know the two nodes, enter them both at once, separated by a comma.'
                                }
                            ]);
                            setComponents(components.filter(a => a !== 'Network'))
                            setInputEnabled(true);
                            setStep(6);
                        })
                    }, 3000)
                }
            }

            if (step === 6) {
                const correct = ["K5C", "B4E"];
                const validButIncorrect = ["C3B", "B4E", "D4A", "1F4", "A2G", "G6J", "B7D", "8F2", "6J2", "K5C"];
                const valid = correct.concat(validButIncorrect)
                let hasInvalid = false

                console.log('Valid', valid)

                let values = lowerInput.split(',');

                const onlyUnique = (value, index, self) => {
                    return self.indexOf(value) === index;
                }

                values = values.map((i, index) => {
                    return i.trim();
                });

                values = values.filter(onlyUnique); // You can't cheat by using one correct answer 3 times.

                values.forEach(val => {
                    if (!valid.includes(val.toUpperCase())) hasInvalid = true
                })

                if (hasInvalid) {
                    return setMessages([
                        ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "We're looking for two network nodes from the water plant, make sure you type them in divided by a comma"
                        }
                    ])
                }

                if (values.length === 2 // Prevents errors from undefined indexes.
                    &&
                    correct.includes(values[0].toUpperCase())
                    &&
                    correct.includes(values[1].toUpperCase())
                    // &&
                    // correct.includes(values[2].toUpperCase())
                ) {
                    setInputEnabled(false);
                    setMessages([...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);
                    setTimeout(() => {
                        setSystemInformation('--- Network System Offline ---');
                        setImage(MotherboardPower);
                        setTimeout(() => {
                            setSystemInformation('');
                            setMessages([...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: 'Well that seems to have worked very well. We now know Yakov can\'t communicate with the device remotely.'
                                }
                            ]);
                            setTimeout(() => {
                                setMessages([...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: 'Well that seems to have worked very well. We now know Yakov can\'t communicate with the device remotely.'
                                    },
                                    {
                                        you: false,
                                        message: `Onto the next section. Which one should we do next? <br/> ${remainingComponentString}`
                                    }
                                ]);
                                setStep(7);
                                setInputEnabled(true);
                            }, delay);
                        }, delay);
                    }, delay);

                } else {
                    setInputEnabled(false);
                    setMessages([...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setTimeout(() => {
                        setMessages([...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: 'Hmmm, I\'ve tried shutting those two down, but the device still seems to be connected to the network. I\'ll re-enable those nodes for now so as to not arouse suspicions. <br/><br/> Have another look and let me know the two you\'d like me to shut down.'
                            }
                        ]);
                        setInputEnabled(true);
                    }, delay);
                }
            }

            if (step === 7) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll try that component..."
                        },
                        {
                            you: false,
                            message: `That wasn't one of the options I'm afraid. Let's try again.<br/><br/> What will it be? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                if (lowerInput === 'power') {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, I'll try that component..."
                        },
                        {
                            you: false,
                            message: `Ah, that's the wrong component and the timer has gone down by five minutes...<br/><br/>What will it be?<br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                }

                if (lowerInput === 'motherboard') {
                    setInputEnabled(false);

                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setSystemInformation('Ready to receive commands. Ability to process two commands at once. \n \n Internal Temperature: 27 degrees');

                    setTimeout(() => {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: "Ok, I'll try that component..."
                            },
                            {
                                you: false,
                                message: 'It looks like the motherboard for this strange device can process two commands at once. <br/><br/> Maybe there\'s a way for us to use this to our advantage and find two commands that will shut the Motherboard down... <br/><br/> Type them below - separated by a comma - and I\'ll enter them into the machine.'
                            }
                        ]);
                        setComponents(components.filter(a => a !== 'Motherboard'))
                        setStep(8);
                        setInputEnabled(true);
                    }, delay);
                }
            }

            if (step === 8) {
                let commands = lowerInput.split(',');
                commands = commands.map((v, i) => {
                    return v.trim();
                });

                let validCommands = {
                    'cook pancake': 42,
                    'open garage door': 9,
                    'turn lights on': 13,
                    'vacuum floor': 37,
                    'dry hands': 17,
                    'toast bread': 28
                }

                if (commands.length === 2
                    &&
                    validCommands[commands[0]]
                    &&
                    validCommands[commands[1]]
                    //&&
                    //validCommands[commands[2]]
                ) {
                    setInputEnabled(false);
                    setMessages([
                        ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok I'll try those two, one second."
                        }
                    ]);

                    //let temp = 27 + validCommands[commands[0]] + validCommands[commands[1]] + validCommands[commands[2]];

                    let temp = 27 + validCommands[commands[0]] + validCommands[commands[1]];

                    if (temp !== 77) {
                        setTimeout(() => {
                            setSystemInformation(`${input}\n\nInternal Temperature: ${temp} degrees`);
                            setTimeout(() => {
                                setSystemInformation(`${input}\n\nInternal Temperature: ${temp} degrees\n\nMotherboard Stable.`);
                                setMessages([
                                    ...messages,
                                    {
                                        you: true,
                                        message: input.split('\n').join('<br/>')
                                    },
                                    {
                                        you: false,
                                        message: "Ok I'll try those three, one second."
                                    },
                                    {
                                        you: false,
                                        message: `So those two commands made the motherboard heat to ${temp} degrees. It seems to have dropped back down to 27 now.<br/><br/>Let's try another two commands.`
                                    }
                                ]);
                                setTimeout(() => {
                                    setSystemInformation('Ready to receive processing commands. Ability to process two commands at once. \n \n Internal Temperature: 27 degrees');
                                    setInputEnabled(true);
                                }, delay);
                            }, delay);
                        }, delay);
                    } else {
                        setTimeout(() => {
                            setSystemInformation(`${input}\n\nInternal Temperature: ${temp} degrees`);
                            setTimeout(() => {
                                setSystemInformation(`${input}\n\nInternal Temperature: ${temp} degrees\n\n--- TEMPERATURE AT CRITICAL LEVEL ---`);
                                setTimeout(() => {
                                    setSystemInformation("--- Motherboard System Offline ---");
                                    setImage(Power);
                                    setTimeout(() => {
                                        setSystemInformation("");
                                        setTimeout(() => {
                                            setMessages([
                                                ...messages,
                                                {
                                                    you: true,
                                                    message: input.split('\n').join('<br/>')
                                                },
                                                {
                                                    you: false,
                                                    message: "Ok I'll try those two, one second."
                                                },
                                                {
                                                    you: false,
                                                    message: "Fantastic work, it looks like we've destroyed the motherboard.<br/><br/>Onto the final component, which must be the power."
                                                }
                                            ]);
                                            setTimeout(() => {
                                                setSystemInformation("First layer of wires: \n Red, Green, Blue, Black, Pink.");
                                                setTimeout(() => {
                                                    setMessages([
                                                        ...messages,
                                                        {
                                                            you: true,
                                                            message: input.split('\n').join('<br/>')
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Ok I'll try those two, one second."
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Fantastic work, it looks like we've destroyed the motherboard.<br/><br/>Onto the final component, which must be the power."
                                                        },
                                                        {
                                                            you: false,
                                                            message: "It looks like there are several layers of wires. Seems we'll need to cut the correct wire in each layer in order to take the power - and therefore the whole device - offline. Let me know which wire you'd like me to cut..."
                                                        }
                                                    ]);
                                                    setStep(9);
                                                    setInputEnabled(true);
                                                }, delay);
                                            }, delay);
                                        }, delay);
                                    }, delay)
                                }, delay)
                            }, delay)
                        }, delay)
                    }
                } else {
                    if (commands.length !== 2) {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input.split('\n').join('<br/>')
                            },
                            {
                                you: false,
                                message: "The machine only accepts commands in groups of two. <br/><br/>Make sure you're sending them over with one command per line.<br/><br/> Let's try again."
                            }
                        ]);
                    } else {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input.split('\n').join('<br/>')
                            },
                            {
                                you: false,
                                message: "I tried those commands, but it seems that at least one of them was incorrect.<br/><br/>Make sure you're trying commands that the machine would understand. <br/><br/><br/>Let's try again."
                            }
                        ]);
                    }
                }
            }

            if (step === 9) {
                if (lowerInput === 'red') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("First layer of wires: \n Red, Green, Blue, Black, Pink.\n\n--- LAYER BYPASSED ---\n\n--- MOVING ONTO THE NEXT LAYER ---");
                        setTimeout(() => {
                            setSystemInformation("Second layer of wires: \n Pink, Yellow, Blue");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                                },
                                {
                                    you: false,
                                    message: "It seems that was correct, we've moved on to the next layer. <br/><br/> Which wire shall I cut this time?"
                                }
                            ]);
                            setStep(10);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['green', 'black', 'blue', 'pink'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("First layer of wires: \n Red, Green, Blue, Black, Pink.\n\n--- TAMPERING DETECTED ---\n\n--- TIMER HAS ADJUSTED FOR ERROR ---");
                        setTimeout(() => {
                            setSystemInformation("First layer of wires: \n Red, Green, Blue, Black, Pink.");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                                },
                                {
                                    you: false,
                                    message: "Oh no, it seems like that was the wrong wire... and the timer has sped up. Try not to get it wrong again!<br/><br/>Which wire would you like me to cut?"
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `That isn't an option, stay focused!`
                    }
                  ]);
                }
            }

            if (step === 10) {
                if (lowerInput === 'blue') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Second layer of wires: \n Pink, Yellow, Blue\n\n--- LAYER BYPASSED ---\n\n--- MOVING ONTO THE NEXT LAYER ---");
                        setTimeout(() => {
                            setSystemInformation("Third layer of wires: \n Pink, Black, Blue, Yellow");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                                },
                                {
                                    you: false,
                                    message: "It seems that was correct, we've moved on to the next layer. <br/><br/> Which wire shall I cut this time?"
                                }
                            ]);
                            setStep(11);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['yellow', 'pink'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Second layer of wires: \n Pink, Yellow, Blue\n\n--- TAMPERING DETECTED ---\n\n--- TIMER HAS ADJUSTED FOR ERROR ---");
                        setTimeout(() => {
                            setSystemInformation("Second layer of wires: \n Pink, Yellow, Blue");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                                },
                                {
                                    you: false,
                                    message: wireCutFailMessage
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `That isn't an option, stay focused!`
                    }
                  ]);
                }
            }

            if (step === 11) {
                if (lowerInput === 'pink') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Third layer of wires: \n Pink, Black, Blue, Yellow\n\n--- LAYER BYPASSED ---\n\n--- MOVING ONTO THE NEXT LAYER ---");
                        setTimeout(() => {
                            setSystemInformation("Fourth layer of wires: \n Green, Pink, Blue, Red, Yellow");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                                },
                                {
                                    you: false,
                                    message: "It seems that was correct, we've moved on to the next layer. <br/><br/> Which wire shall I cut this time?"
                                }
                            ]);
                            setStep(12);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['yellow', 'black', 'blue'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Third layer of wires: \n Pink, Black, Blue, Yellow\n\n--- TAMPERING DETECTED ---\n\n--- TIMER HAS ADJUSTED FOR ERROR ---");
                        setTimeout(() => {
                            setSystemInformation("Third layer of wires: \n Pink, Black, Blue, Yellow");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                                },
                                {
                                    you: false,
                                    message: wireCutFailMessage
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `That isn't an option, stay focused!`
                    }
                  ]);
                }
            }

            if (step === 12) {
                if (lowerInput === 'blue') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                        }
                    ]);

                    setStopTimer(true);
                    setTimeout(() => {
                        setSystemInformation("Fourth layer of wires: \n Green, Pink, Blue, Red, Yellow\n\n--- LAYER BYPASSED ---\n\n--- ALL WIRE LAYERS COMPLETE ---");
                        setTimeout(() => {
                            setSystemInformation("--- Power System Offline ---");
                            setImage(AllDisabled);
                            setTimeout(() => {
                                setSystemInformation(`--- ALL SYSTEMS OFFLINE ---\n\n--- COUNTDOWN TERMINATED AT ${timerFrozen ? '60:00+' : calculateTimerString(timer, 2)} ---`);
                                setImage(Defused);
                                setTimeout(() => {
                                    if (!timerFrozen) {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                                            },
                                            {
                                                you: false,
                                                message: `Amazing, we’ve disabled all the components! Fantastic work on disarming that device! The whole of Molvaria is safe from the horrors that Yakov intended to inflict on them. Right - I think we have enough time left to find him! I was doing some research on where he might be whilst you were disarming the device. <a href='http://www.conspiralist.com/message-from-claire-1?playerTime=${ image === Defused ? '45:00 +' : elapsedTime(timer, 0)}'>Click here</a> quickly to access the information I found.`
                                            }
                                        ]);
                                    } else {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                                            },
                                            {
                                                you: false,
                                                message: "Amazing, we’ve disabled all the components! Fantastic work on disarming that device! The whole of Molvaria is safe from the horrors that Yakov intended to inflict on them. Unfortunately, I think that whilst we were disarming the device he will have been able to escape. But that's ok, we stopped his plan all the same. The next step is to release the news about what the government was up to. I was working on some things whilst you were disarming the device. <a href='http://www.conspiralist.com/message-from-claire-2/?playerTime=60:00+'>Click here</a> to see our next steps."
                                            }
                                        ]);
                                    }
                                }, delay);
                            }, delay);
                        }, delay);
                    }, delay);
                } else if (['red', 'pink', 'green', 'yellow'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Fourth layer of wires: \n Green, Pink, Blue, Red, Yellow\n\n--- TAMPERING DETECTED ---\n\n--- TIMER HAS ADJUSTED FOR ERROR ---");
                        setTimeout(() => {
                            setSystemInformation("Fourth layer of wires: \n Green, Pink, Blue, Red, Yellow");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Ok, I'll cut the ${lowerInput} wire, here goes!`
                                },
                                {
                                    you: false,
                                    message: "Oh no, it seems like that was the wrong wire... and the timer has sped up. Try not to get it wrong again!<br/><br/>Which wire would you like me to cut?"
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `That isn't an option, stay focused!`
                    }
                  ]);
                }
            }
        }, delay);
    }

    const TimerFunction = React.useCallback(() => {
        if (!stopTimer) {
            if ((timer - (1 + timePenalty)) < 1 && !timerFrozen) {
                console.log(timerFrozen);
                setTimerFrozen(true);
                setError(true);
            } else {
                timerDispatch();
                setTimePenalty(0);
            }
        }
    }, [stopTimer, timer, timerFrozen, timerDispatch, setError, timePenalty]);

    React.useEffect(() => {
        var clock = null;

        const resetTimeout = () => {
            clock = setTimeout(TimerFunction, timerInterval * 1000);
        }

        console.log('resetTimer');
        resetTimeout();

        return () => {
            clearTimeout(clock);
        }
    }, [timerInterval, TimerFunction, initialState]);

    React.useEffect(() => {
        document.querySelector('#chat-box').scrollTop = document.querySelector('#chat-box').scrollHeight;
    }, [ChatLog]);

    return (
        <div className="main-game">
            <Panel variant='outside' shadow style={{ width: '100%', height: '100%', padding: '1rem' }} className="left-panel">
                <Fieldset>
                    <div className="timer">
                        <p>Time Until Release:</p>
                        <p style={{ color: timer < 300 ? 'red' : 'black' }}>{ timerFrozen ? 'FROZEN' : `${ timerValue }` }</p>
                    </div>
                </Fieldset>
                <Fieldset label="System Information">
                    <TextField
                        value={ systemInformation }
                        placeholder='Awaiting Instructions...'
                        onChange={(e) => {}}
                        fullWidth
                        multiline
                        style={{ height: '100%', minHeight: 166 }}
                    />
                </Fieldset>

                <Fieldset label="Device Diagnostics" style={{ maxHeight: 150 }}>
                    <div className="gif-panel">
                        <div className="content">
                            <img src={image} alt="" style={{ height: '100%', width: '100%' }}/>
                        </div>
                    </div>
                </Fieldset>
            </Panel>
            <Panel variant='outside' shadow style={{ width: '100%', height: '100%', padding: '1rem' }} className="right-panel">
                <Fieldset label="Z8 WTP Internal Chat:">
                    <div className="chat-section">
                        <TextField
                            value=""
                            placeholder='*** FOR INTERNAL STAFF USE ONLY ***'
                            onChange={(e) => {}}
                            fullWidth
                            className="internal-use-box"
                        />
                        <div id="chat-box" className="gif-panel chatlog no-before" style={{ overflowY: 'scroll', height: `${ 330 - (25 * (rows - 1))}px`, scrollPaddingTop: '1rem' }}>
                            { ChatLog }
                        </div>
                        <Fieldset className="input-box">
                            <TextField
                                ref={userInput}
                                // value={inputText}
                                placeholder="Start typing here..."
                                // onChange={debounce((e) => { setInputText(e.target.value); console.log('Changed', e.target.value) }, 1000)}
                                onKeyDown={ handleKeyDown }
                                enabled={ inputEnabled }
                                fullWidth
                                multiline={rows !== 1}
                                rows={rows}
                            />
                            <Button onClick={ sendMessage } disabled={!inputEnabled} style={{ height: '100%' }}>
                                Send
                            </Button>
                        </Fieldset>
                    </div>
                </Fieldset>
            </Panel>
        </div>
    )
}
